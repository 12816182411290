/* Custom scrollbar */
::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; /* Change the track color if needed */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #c2c2c2; /* Change the handle color */
    border-radius: 10px; /* Adjust the border radius for a rounder handle */
  }
  