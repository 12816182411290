/* ProjectList.css */
/* Utilizing theme colors with CSS variables */

.grid-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.search-field-padding {
  padding: 10px;
}

.list-item-custom {
  background-color: var(--color-background-paper); /* white */
  border-radius: 10px;
  margin: 0 0;
  max-width: 95%;
  transition: all 0.3s;
}

.list-item-custom:hover {
  background-color: var(--color-divider); /* soft grey */
  cursor: pointer;
}

.list-item-alt {
  background-color: var(--color-background-default); /* slightly off-white */
}

.list-item-selected {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.icon-button-transition {
  transition: all 0.3s;
}

.dialog-paper-custom {
  transform: translateY(0);
  transition: transform 0.3s;
  border-radius: 10px;
}


/* ProjectList.css */
/* Styling for ultra-thin custom scrollbar with hover visibility */
.custom-scrollbar {
  overflow-y: auto;
  max-height: calc(100vh - 270px); /* Adjust 300px or as needed */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
}

/* Style the scrollbar thumb (Chrome, Edge, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Adjust the color as needed */
  border-radius: 4px; /* Adjust the border-radius as needed */
}

/* Hide the scrollbar track in Safari */
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.project-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  
}
